import React, { useState } from 'react';


import { Outlet, Link, useLocation, NavLink } from "react-router-dom";
import './Layout.css'
import logo from '../img/logo.png'

import { signOut, auth } from "../Utils/firebaseConfig";

import { Divide as Hamburger } from 'hamburger-react'


const Layout = ({ hideHeaderPaths = [] }) => {
  const [isOpen, setOpen] = useState(false)


  // HANDELING AUTH FOR ALL ROUTES
  auth.onAuthStateChanged((user) => {
    if (user) {
      var userList = process.env.REACT_APP_VERIFIED_EMAILS.split(",")
      if (!(/.*@motionair[.]no$/.test(user.email))) {
        if ((userList.indexOf(user.email) > -1) === false) {
          signOut(auth).then(() => {
          }).catch((error) => {
            console.log(error)
          });
        }
      } else {
      }
    } else {
    }
  });


  const { pathname } = useLocation();


  // var url = window.location.href;
  // var lastPart = url.substr(url.lastIndexOf('/') + 1);

  // if (lastPart === 'Agenda') {
  //   let agenda = document.getElementsByClassName('agenda')
  //   let test = ReactDOM.findDOMNode(Link)
  //   console.log(agenda)
  //   // document.getElementsByClassName('calendar').classList.remove("active")
  // } else if (lastPart === 'Calendar') {
  //   document.getElementsByClassName('calendar').classList.add("active")
  //   document.getElementsByClassName('agenda').classList.remove("active")
  // }
  // console.log(lastPart)

  // const itemClick = (e) => {
  //   var elems = document.querySelectorAll(".active");
  //   [].forEach.call(elems, function(el) {
  //     el.classList.remove("active");
  //   });
  //   e.target.className = "active";
  // }






  const logOut = () => {
    signOut(auth).then(() => {
      console.log('warning', `You are logged out!`)
    }).catch((error) => {
      console.log(error)
    });
  }


  return (
    <>
      {!hideHeaderPaths.includes(pathname) &&
        <>
          <nav>
            <div className='mobile-menu'>
              <div className='buttonContainer'>
                <ul className={isOpen ? 'mobile-nav-ul-open' : 'mobile-nav-ul-closed'}>
                  <li>
                    <img className="logo-image" src={logo} alt=""></img>
                  </li>

                  <NavLink
                    to="/Calendar"
                    className={({ isActive }) =>
                      isActive ? 'active' : 'regular'
                    }
                  >
                    Calendar
                  </NavLink>
                  <NavLink
                    to="/Agenda"
                    className={({ isActive }) =>
                      isActive ? 'active' : 'regular'
                    }
                  >
                    Agenda
                  </NavLink>
                  <li className="log-out-btn">
                    <a onClick={logOut}>Log ut</a>
                  </li>
                </ul>
              </div>
            </div>

            <ul className="nav-ul">
              <li>
                <img className="logo-image" src={logo} alt=""></img>
              </li>
              {/* <li >
                <Link className={'calendar'} to="/Calendar">Calendar</Link>
              </li> */}
              <NavLink
                to="/Calendar"
                className={({ isActive }) =>
                  isActive ? 'active' : 'regular'
                }
              >
                Calendar
              </NavLink>
              <NavLink
                to="/Agenda"
                className={({ isActive }) =>
                  isActive ? 'active' : 'regular'
                }
              >
                Agenda
              </NavLink>
              {/* <li >
                <Link className={'agenda'} to="/Agenda">Agenda</Link>
              </li> */}
              <li className="log-out-btn">
                <a onClick={logOut}>Log ut</a>
              </li>
            </ul>
          </nav>

        </>}
      <Outlet />
    </>
  )
};

export default Layout;