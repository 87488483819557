import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import Calendar from "./Pages/Calendar";
import LogIn from "./Pages/LogIn";
import NoPage from "./Pages/NoPage";
import Agenda from "./Pages/Agenda";
import './index.css'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout hideHeaderPaths={["/"]} />}>
          <Route index element={<LogIn />} />
          <Route path="Calendar" element={<Calendar />} />
          <Route path="Agenda" element={<Agenda />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </StrictMode>
);
