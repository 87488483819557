import { database, collection, getDocs, doc, addDoc, query, where, updateDoc, deleteDoc } from './firebaseConfig'


const fetchEvents = async (eventDatabase) => {
  const querySnapshot = await getDocs(collection(database, eventDatabase));
  let allEvents = []
  querySnapshot.forEach((doc) => {
      allEvents.push({
          ...doc.data(),
          start: doc.data().start.toDate(),
          end: doc.data().end.toDate()
      })
  });
  return allEvents
}





const addEvents = async (eventDatabase, id, title, description, start, end, allDay, status, color, resource, editable) => {
  try {
    await addDoc(collection(database, eventDatabase), {
      id: id,
      title: title,
      description: description,
      start: start,
      end: end,
      allDay: allDay,
      status: status,
      color: color,
      resource: resource,
      editable: editable
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};







const updateEvent = async (eventDatabase, id, title, description, start, end, allDay, status, color, resource, editable) => {
  const userRef = collection(database, eventDatabase);
  const q = query(userRef, where('id', '==', `${id}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    updateDoc(doc.ref, {
      id: id,
      title: title,
      description: description,
      start: start,
      end: end,
      allDay: allDay,
      status: status,
      color: color,
      resource: resource,
      editable: editable

    })
  })
}






const deleteEvent = async (eventDatabase, event) => {
  const userRef = collection(database, eventDatabase);
  const q = query(userRef, where('id', '==', `${event.id}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((item) => {
    try {
      deleteDoc(doc(database, eventDatabase, item.ref.id))
      console.log(`deleted post with id: ${item.ref.id}`)
    } catch (ex) {
      console.error(`Delete FAILED: ${ex.message}`);
      throw ex;
    }
  })
};




const restoreEvent = async (eventDatabase, event) => {
  try {
      await addDoc(collection(database, eventDatabase), {
          ...event
      });
  } catch (e) {
      console.error("Error adding document: ", e);
  }
}

export { fetchEvents, updateEvent, addEvents, deleteEvent, restoreEvent }