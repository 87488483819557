import React, { useEffect, useRef } from 'react';

import { Eventcalendar, toast, setOptions, Popup, Button, Input, Textarea, Datepicker, localeNo, snackbar, Checkbox, Switch } from '@mobiscroll/react';
import { onAuthStateChanged, auth, collection, database, onSnapshot } from '../Utils/firebaseConfig'
import { v4 as uuidv4 } from 'uuid';

import { fetchEvents, addEvents, updateEvent, deleteEvent, restoreEvent } from '../Utils/firebaseCRUD';
import { resources } from "../Utils/resources"

import '../styles.scss'
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import './CalendarComponent.css'

// NORDFJRORDVEGSERVICE

setOptions({
    locale: localeNo,
    theme: 'ios',
    themeVariant: 'light'
});

const responsivePopup = {
    medium: {
        display: 'anchored',
        width: 400,
        fullScreen: false,
        touchUi: false
    },
    large: {
        display: 'center',
        maxHeight: 1200,
        maxWidth: 800,
        fullScreen: true,
        touchUi: false
    }
};
const colorPopup = {
    medium: {
        display: 'anchored',
        touchUi: false,
        buttons: []
    }
}
const colors = ['#ffeb3c', '#ff9900', '#f44437', '#ea1e63', '#9c26b0', '#3f51b5', '#5ac8fa', '#009788', '#4baf4f', '#7e5d4e'];



const Calendar = ({ user }) => {

    // CUSTOM CODE:
    // DATABASE CONSTANT, CHANGE FOR TESTING PURPOSES:
    const eventDatabase = process.env.REACT_APP_SERVER_LOCATION

    const [isChecked, setIsChecked] = React.useState([]);
    const [isEditable, setIsEditable] = React.useState(false);
    const [mobileShow, setMobileShow] = React.useState(true);
    const [zoomLevel, setZoomLevel] = React.useState(1440);
    const [isHoverOpen, setIsHoverOpen] = React.useState(false);
    const timerRef = React.useRef(null);
    const [eventContent, setEventContent] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState(false);

    //SET CALENDAR TO NOT BE EDITABLE. SEE USE EFFECT FOR ADMIN CHANGES
    const [clickToCreate, setClickToCreate] = React.useState('');
    const [dragToCreate, setDragToCreate] = React.useState(false);
    const [dragToMove, setDragToMove] = React.useState(false);
    const [dragToResize, setDragToResize] = React.useState(false);
    
    const admins = ['test@test.com','matias.lien@nordfjordvegservice.no', 'frode@nordfjordvegservice.no', 'kristian@nordfjordvegservice.no']


    const [calendarColors, setCalendarColors] = React.useState(

    );

    // STANDARD CODE:
    const [myEvents, setMyEvents] = React.useState();
    const [tempEvent, setTempEvent] = React.useState(null);
    const [isOpen, setOpen] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    const [anchor, setAnchor] = React.useState(null);
    const [start, startRef] = React.useState(null);
    const [end, endRef] = React.useState(null);
    const [popupEventTitle, setTitle] = React.useState('');
    const [popupEventDescription, setDescription] = React.useState('');
    const [popupEventAllDay, setAllDay] = React.useState(true);
    const [popupEventDate, setDate] = React.useState([]);
    const [popupEventStatus, setStatus] = React.useState('busy');
    const [mySelectedDate, setSelectedDate] = React.useState(new Date());
    const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
    const [colorAnchor, setColorAnchor] = React.useState(null);
    const [selectedColor, setSelectedColor] = React.useState('');
    const [tempColor, setTempColor] = React.useState('');
    const colorPicker = React.useRef();
    const colorButtons = React.useMemo(() => [
        'cancel',
        {
            text: 'Set',
            keyCode: 'enter',
            handler: () => {
                setSelectedColor(tempColor);
                setColorPickerOpen(false);
            },
            cssClass: 'mbsc-popup-button-primary'
        }
    ], [tempColor]);

    let viewSettings = {
        timeline: {
            type: 'year',
            weekNumbers: true,
            rowHeight: 'variable',
            timeCellStep: zoomLevel
        }
    };







    const getArr = React.useCallback((arr, flat = []) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].children && arr[i].children.length) {
                flat.push(arr[i]);
                console.log(arr[i])
                getArr(arr[i].children, flat);
            }
        }
        return flat;
    })




    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                if (admins.includes(user.email) === true) {
                    console.log('admin')
                    setIsAdmin(true)
                    setClickToCreate('double');
                    setDragToCreate(true);
                    setDragToMove(true);
                    setDragToResize(true);
                }

            } else {
                // User is signed out
                // ...
            }
        });

        //THIS INITIATES REALTIME LISTENERS
        const userRef = collection(database, eventDatabase);
        const unsubsctibe = onSnapshot(userRef, snapshot => {
            setMyEvents(snapshot.docs.map(doc => ({
                id: doc.data().id,
                title: doc.data().title,
                description: doc.data().description,
                start: doc.data().start.toDate(),
                end: doc.data().end.toDate(),
                allDay: doc.data().allDay,
                status: doc.data().status,
                color: doc.data().color,
                resource: doc.data().resource,
                editable: doc.data().editable,
                busy: doc.data().busy
            })))
        })


        return () => {
            unsubsctibe()
            console.log('unsubscribed!')
        }
    }, [eventDatabase])


    //USE EFFECT FOR RESOURCE COLOR UPDATES
    useEffect(() => {
        const freelanceResourceAvailable = () => {
            let freelanceResources = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
            let equipmentResources = [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37]
            let colors = [{
                date: new Date(),
                background: '#e3ffe3',
            }]
            if (myEvents !== undefined) {
                myEvents.map((event) => {
                    if (event.resource.some(r => freelanceResources.indexOf(r) >= 0)) {  // IF EVENT IN FREELANCE GROUP
                        colors.push({
                            start: event.start,
                            end: event.end,
                            resource: [1000],
                            background: `${event.color}${Math.floor(0.3 * 255).toString(16).padStart(2, 0)}` // 0.3 opacity and event.color HEX
                        })
                    } else if (event.resource.some(r => equipmentResources.indexOf(r) >= 0)) {  // IF EVENT IN FREELANCE GROUP
                        colors.push({
                            start: event.start,
                            end: event.end,
                            resource: [1001],
                            background: `${event.color}${Math.floor(0.3 * 255).toString(16).padStart(2, 0)}` // 0.3 opacity and event.color HEX
                        })
                    }
                })
                colors.push({ ...calendarColors })
                setCalendarColors(colors)
            }
        }
        freelanceResourceAvailable()
    }, [myEvents])




    const handleResourceCheckInEvent = (resource) => {
        if (!isChecked.includes(resource.id)) {
            setIsChecked([...isChecked, resource.id]);
        } else {
            const index = isChecked.indexOf(resource.id)
            const checked = isChecked
            if (index > -1) { // only splice array when item is found
                checked.splice(index, 1); // 2nd parameter means remove one item only
            }
            setIsChecked([...checked])
        }
    }
    const test = true

    const myScheduleEvent = React.useCallback((data) => {
        const ev = data.original;
        const color = data.color;
        // console.log(data)
        return <div className={`md-timeline-template-event md-timeline-template-event-busy ${mobileShow ? "" : "mobile-toggle"}`} style={{
            borderColor: `${color}${Math.floor(1 * 255).toString(16).padStart(2, 0)}`,
            background: `${color}${Math.floor(0.4 * 255).toString(16).padStart(2, 0)}`
        }}>
            <div className="mbsc-schedule-event-inner md-timeline-template-event-cont">
                {/* <span className={"mbsc-icon mbsc-font-icon mbsc-icon-" + ev.taskType} style={{ background: color }}></span> */}
                <div id="flex-event">
                    <span className="md-timeline-template-title">{ev.title}</span>
                    <span className="md-timeline-template-desc" >{ev.description}</span>
                </div>
            </div>
        </div>
    });

    const myScheduleEventContent = React.useCallback((data) => {
        const ev = data.original;
        return <div className="md-timeline-template-event" >
            <span className="md-timeline-template-title">{ev.title}</span>
            <span className="md-timeline-template-time" >{ev.description}</span>
        </div>
    });


    const renderCustomResource = (resource) => {
        if (!resource.styling) {
            return (
                <div className="resource-template-content">
                    <h5 className="resource-name">{resource.name}</h5>
                    <div className="resource-description">{resource.description}</div>
                </div>
            )
        } else if (resource.styling === 'person') {
            return (
                <div className="resource-template-content">
                    <div className="resource-name">{resource.name}</div>
                    <div className="resource-description">{resource.description}</div>
                </div>
            )
        } else if (resource.styling === 'header') {
            return (
                <div className="resource-header-content">
                    <h6 className="resource-header-name">{resource.name}</h6>
                </div>
            )
        }
    }

    const renderResourceHeader = () => {
        return (
            <div className="md-resource-header-template-title">
                <div className="md-resource-header-template-name">Room</div>
                <div className="md-resource-header-template-seats">Capacity</div>
            </div>
        )
    }


    const invalidRecources = React.useMemo(() => {
        return [{
            recurring: {
                repeat: 'daily'
            },
            resource: ['100', '101', '1001'] // disable the parent resources with id 'gro2' and 'gro3'
        }]
    }, []);


    const calendarMarkedDates = React.useMemo(() => {
        return [
            new Date(2020, 2, 15),
            new Date(2020, 2, 22),
            {
                start: new Date(2020, 2, 23),
                end: new Date(2020, 2, 24),
                color: 'red'
            },
            {
                color: 'green',
                recurring: { repeat: 'yearly', month: 12, day: 24 }
            }
        ]
    }, []);



    function flattenArr(arr, flat = []) {
        for (let i = 0; i < arr.length; i++) {
            flat.push(arr[i]);
            if (arr[i].children && arr[i].children.length) {
                flat.pop() // remove headers from list
                flattenArr(arr[i].children, flat);
            }
        }
        return flat;
    }

    function flattenArrAll(arr, flat = []) {
        for (let i = 0; i < arr.length; i++) {
            flat.push(arr[i]);
            if (arr[i].children && arr[i].children.length) {
                flattenArrAll(arr[i].children, flat);
            }
        }
        return flat;
    }


    const handleEventInvoiced = React.useCallback((ev => {
        setIsEditable(ev.target.checked)
    }));





    const showMobileMenu = (e) => {
        setMobileShow(!mobileShow)
        let el1 = document.querySelectorAll(".motion-air-calendar .mbsc-timeline-resource-empty");
        let el2 = document.querySelectorAll(".motion-air-calendar .mbsc-timeline-resource-col");
        let el3 = document.querySelectorAll(".motion-air-calendar .mbsc-timeline-resources");
        let el4 = document.querySelectorAll(".mbsc-timeline-resource.mbsc-ios.mbsc-ltr");
        if (mobileShow === true) {
            el1.forEach(el => el.classList.add('mobile-menu-open'))
            el2.forEach(el => el.classList.add('mobile-menu-open'))
            el3.forEach(el => el.classList.add('mobile-menu-open'))
            el4.forEach(el => el.classList.add('mobile-menu-open-padding'))
        } else {
            el1.forEach(el => el.classList.remove('mobile-menu-open'))
            el2.forEach(el => el.classList.remove('mobile-menu-open'))
            el3.forEach(el => el.classList.remove('mobile-menu-open'))
            el4.forEach(el => el.classList.remove('mobile-menu-open-padding'))
        }
    }





    // STANDARD CODE:
    const saveEvent = React.useCallback(() => {
        const newEvent = {
            id: uuidv4(),
            title: popupEventTitle,
            description: popupEventDescription,
            start: popupEventDate[0].toString(),
            end: popupEventDate[1].toString(),
            allDay: popupEventAllDay,
            status: popupEventStatus,
            color: selectedColor,
            resource: isChecked,
            editable: isEditable
        };
        if (isEdit) {
            // update the event in the list
            const index = myEvents.findIndex(x => x.id === tempEvent.id);
            const newEventList = [...myEvents];

            newEventList.splice(index, 1, newEvent);

            // here you can update the event in your storage as well
            updateEvent(
                eventDatabase,
                tempEvent.id,
                popupEventTitle,
                popupEventDescription,
                popupEventDate[0],
                popupEventDate[1],
                popupEventAllDay,
                popupEventStatus,
                selectedColor,
                isChecked,
                !isEditable
            ).then(() => {
                toast({
                    message: 'Successfully edited event'
                });

                console.log('Successfully edited event with ID: ', tempEvent.id)
                fetchEvents(eventDatabase).then((events) => {
                    setMyEvents(events)
                })
            });

        } else {

            // add the new event to the list
            setMyEvents([...myEvents, newEvent]);

            // FROM Firebase
            addEvents(
                eventDatabase,
                uuidv4(),
                popupEventTitle,
                popupEventDescription,
                popupEventDate[0],
                popupEventDate[1],
                popupEventAllDay,
                popupEventStatus,
                selectedColor,
                isChecked,
                !isEditable
            )

        }
        setSelectedDate(popupEventDate[0]);
        fetchEvents(eventDatabase).then((events) => {
            setMyEvents(events)
        })

        // close the popup
        setOpen(false);
    }, [eventDatabase, isEditable, isEdit, myEvents, popupEventAllDay, popupEventDate, popupEventDescription, popupEventStatus, popupEventTitle, tempEvent, selectedColor, isChecked]);

    const deleteLocalEvent = React.useCallback((event) => {

        deleteEvent(eventDatabase, event).then(() => {
            fetchEvents(eventDatabase).then((events) => {
                setMyEvents(events)
                console.log('event deleted')
            })
        })
        setTimeout(() => {
            snackbar({
                button: {
                    action: () => {
                        restoreEvent(eventDatabase, event).then(() => {
                            toast({
                                message: 'Event successfully restored'
                            });
                            fetchEvents(eventDatabase).then((events) => {
                                setMyEvents(events)
                            })
                        })
                    },
                    text: 'Undo'
                },
                message: 'Event deleted'

            });
            fetchEvents(eventDatabase).then((events) => {
                setMyEvents(events)
            })

        });
        toast({
            message: 'Event successfully deleted!'
        });
    }, []);

    const loadPopupForm = React.useCallback((event) => {
        setTitle(event.title);
        setDescription(event.description ? event.description : " ");
        setDate([event.start, event.end]);
        setAllDay(event.allDay || true);
        setStatus(event.status || 'busy');
    }, []);

    // handle popup form changes

    const titleChange = React.useCallback((ev) => {
        setTitle(ev.target.value);
    }, []);

    const descriptionChange = React.useCallback((ev) => {
        setDescription(ev.target.value);
    }, []);

    const allDayChange = React.useCallback((ev) => {
        setAllDay(ev.target.checked);
    }, []);

    const dateChange = React.useCallback((args) => {
        setDate(args.value);
    }, []);

    const statusChange = React.useCallback((ev) => {
        setStatus(ev.target.value);
    }, []);

    const onDeleteClick = React.useCallback(() => {
        deleteLocalEvent(tempEvent);
        setOpen(false);
    }, [deleteLocalEvent, tempEvent]);



    // scheduler options

    const onSelectedDateChange = React.useCallback((event) => {
        setSelectedDate(event.date);
    }, []);

    const onEventClick = React.useCallback((args) => {
        if (isAdmin === true) {
            setEdit(true);
            setTempEvent({ ...args.event });
            setIsEditable(!args.event.editable)
            setSelectedColor(args.event.color || flattenArr(resources).find(r => r.id === args.event.resource).color);
            // fill popup form with event data
            loadPopupForm(args.event);
            setAnchor(args.domEvent.target);
            setOpen(true);

            // Custom code:
            // Deals with witch resources are selected for this event:
            setIsChecked(args.event.resource)
        }
    }, [loadPopupForm, isAdmin]);

    const onEventCreated = React.useCallback((args) => {
        setIsEditable(false)
        setEdit(false);
        setTempEvent(args.event)
        setSelectedColor(flattenArrAll(resources).find(r => r.id === args.event.resource).color);
        // fill popup form with event data
        loadPopupForm(args.event);
        setAnchor(args.target);

        //Custom code:
        setIsChecked([args.event.resource])

        // open the popup
        setOpen(true);
    }, [loadPopupForm]);

    const onEventDeleted = React.useCallback((args) => {
        deleteLocalEvent(args.event)
    }, [deleteLocalEvent]);

    const onEventUpdated = React.useCallback((args) => {
        // here you can update the event in your storage as well, after drag & drop or resize

        updateEvent(
            eventDatabase,
            args.event.id,
            args.event.title,
            args.event.description,
            args.event.start,
            args.event.end,
            args.event.allDay,
            args.event.status,
            args.event.color,
            args.event.resource,
            args.event.editable

        ).then(() => {
            toast({
                message: 'Successfully edited event'
            });

            fetchEvents(eventDatabase).then((events) => {
                setMyEvents(events)
                console.log('Successfully edited event with ID: ', args.event.id)
            })
        })
    }, []);

    // datepicker options
    const controls = React.useMemo(() => popupEventAllDay ? ['date'] : ['datetime'], [popupEventAllDay]);
    const responsiveOptions = React.useMemo(() => popupEventAllDay ? {
        medium: {
            controls: ['calendar'],
            touchUi: false
        }
    } : {
        medium: {
            controls: ['calendar', 'time'],
            touchUi: false
        }
    }, [popupEventAllDay]);

    // popup options
    const headerText = React.useMemo(() => isEdit ? 'Edit event' : 'New Event', [isEdit]);
    const popupButtons = React.useMemo(() => {
        if (isEdit) {
            return ['cancel', {
                handler: () => {
                    saveEvent();
                },
                keyCode: 'enter',
                text: 'Save',
                cssClass: 'mbsc-popup-button-primary'
            }];
        } else {
            return ['cancel', {
                handler: () => {
                    saveEvent();
                },
                keyCode: 'enter',
                text: 'Add',
                cssClass: 'mbsc-popup-button-primary'
            }];
        }
    }, [isEdit, saveEvent]);

    const onClose = React.useCallback(() => {
        if (!isEdit) {
            // refresh the list, if add popup was canceled, to remove the temporary event
            setMyEvents([...myEvents]);
        }
        setOpen(false);
    }, [isEdit, myEvents]);

    const selectColor = React.useCallback((color) => {
        setTempColor(color)
    }, []);

    const openColorPicker = React.useCallback((ev) => {
        selectColor(selectedColor);
        setColorAnchor(ev.currentTarget);
        setColorPickerOpen(true);
    }, [selectColor, selectedColor]);

    const changeColor = React.useCallback((ev) => {
        const color = ev.currentTarget.getAttribute('data-value');
        selectColor(color);
        if (!colorPicker.current.s.buttons.length) {
            setSelectedColor(color);
            setColorPickerOpen(false);
        }
    }, [selectColor, setSelectedColor]);

    const onPickerClose = React.useCallback(() => {
        setColorPickerOpen(false);
    }, []);


    const onPageChange = React.useCallback(() => {
        console.log('PAGE CHANGE')
    }, []);

    // const [myEvents, setMyEvents] = React.useState();
    // const [tempEvent, setTempEvent] = React.useState(null);
    // const [isOpen, setOpen] = React.useState(false);
    // const [isEdit, setEdit] = React.useState(false);
    // const [anchor, setAnchor] = React.useState(null);
    // const [start, startRef] = React.useState(null);
    // const [end, endRef] = React.useState(null);
    // const [popupEventTitle, setTitle] = React.useState('');
    // const [popupEventDescription, setDescription] = React.useState('');
    // const [popupEventAllDay, setAllDay] = React.useState(true);
    // const [popupEventDate, setDate] = React.useState([]);
    // const [popupEventStatus, setStatus] = React.useState('busy');
    // const [mySelectedDate, setSelectedDate] = React.useState(new Date());
    // const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
    // const [colorAnchor, setColorAnchor] = React.useState(null);
    // const [selectedColor, setSelectedColor] = React.useState('');
    // const [tempColor, setTempColor] = React.useState('');
    // const colorPicker = React.useRef();

    const onEventHoverIn = React.useCallback((args) => {
        const event = args.event
        setTitle(event.title)
        setDescription(event.description)
        setAnchor(args.domEvent.target);
        setSelectedColor(event.color)
        setIsHoverOpen(true)

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        const resourcesInEvent = []
        args.event.resource.forEach(element => flattenArr(resources).filter((obj) => {
            if (obj.id == element) {
                resourcesInEvent.push(obj)
            }

        }))
        resourcesInEvent.sort(function (a, b) {
            return (a.id - b.id) || a.name.localeCompare(b.name);
        });
        setEventContent([...resourcesInEvent])

    }, []);

    const onEventHoverOut = React.useCallback(() => {
        timerRef.current = setTimeout(() => { setIsHoverOpen(false); }, 200);
    }, []);

    const onMouseEnter = React.useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    }, []);

    const onMouseLeave = React.useCallback(() => {
        timerRef.current = setTimeout(() => { setIsHoverOpen(false); }, 200);
    }, []);



    return <div >
        <button id={"btnHideResource"} onClick={showMobileMenu}>
            {mobileShow ? "Hide resource" : "Show resource"}
        </button>
        <Eventcalendar
            className='motion-air-calendar'
            view={viewSettings}
            data={myEvents}
            resources={resources}
            clickToCreate={clickToCreate}
            dragToCreate={dragToCreate}
            dragToMove={dragToMove}
            dragToResize={dragToResize}
            selectedDate={mySelectedDate}
            onSelectedDateChange={onSelectedDateChange}
            onEventClick={onEventClick}
            onEventCreated={onEventCreated}
            onEventDeleted={onEventDeleted}
            onEventUpdated={onEventUpdated}
            colors={calendarColors}
            renderScheduleEvent={test ? myScheduleEvent : ""}
            renderScheduleEventContent={myScheduleEventContent}
            // renderResource={renderCustomResource}
            invalid={invalidRecources}
            // renderResourceHeader={renderResourceHeader}
            onEventHoverIn={onEventHoverIn}
            onEventHoverOut={onEventHoverOut}

        />

        <Popup
            display="bottom"
            fullScreen={true}
            contentPadding={false}
            headerText={headerText}
            anchor={anchor}
            buttons={popupButtons}
            isOpen={isOpen}
            onClose={onClose}
            responsive={responsivePopup}
            closeOnOverlayClick={true}
        >
            <div className="mbsc-form-group">

                {isEditable ? <Input disabled={true} label="Title" value={popupEventTitle} onChange={titleChange} ></Input> : <Input label="Title" value={popupEventTitle} onChange={titleChange} ></Input>}

                {isEditable ? <Textarea disabled={true} label="Description" value={popupEventDescription} onChange={descriptionChange} /> : <Textarea label="Description" value={popupEventDescription} onChange={descriptionChange} />}
                <div onClick={openColorPicker} className="event-color-c">
                    <div className="event-color-label">Color</div>
                    <div className="event-color" style={{ background: selectedColor }}></div>
                </div>

                <div className='resource-row-container'>
                    <div className='resource-row-1'>
                        {flattenArr(resources).map((item, index) => {

                            // LastCrew sets the position of the whitespace on the popup component
                            var lastCrew = resources[0].children.length - 1;
                            if (index === lastCrew) {
                                if (isEditable === true) {
                                    return (
                                        <div className='last-crew-entry' key={item.id}>
                                            <Checkbox disabled={true} label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)} />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='last-crew-entry' key={item.id}>
                                            <Checkbox label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)} />
                                        </div>
                                    )
                                }
                            }
                            if (flattenArr(resources).length / 2 > index) {
                                if (isEditable === true) {
                                    return (
                                        <div key={item.id}>
                                            <Checkbox disabled={true} label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)} />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={item.id}>
                                            <Checkbox label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)} />
                                        </div>
                                    )
                                }
                            }
                        })}
                    </div>
                    <div className='resource-row-2'>
                        {flattenArr(resources).map((item, index) => {
                            if (flattenArr(resources).length / 2 <= index) {
                                if (isEditable === true) {
                                    return (
                                        <div key={item.id}>
                                            <Checkbox disabled={true} label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)} />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={item.id}>
                                            <Checkbox label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)} />
                                        </div>
                                    )
                                }
                            }
                        })}
                    </div>
                </div>

            </div>
            <div className="mbsc-form-group">
                {isEditable ? <Switch disabled={true} label="All-day" checked={popupEventAllDay} onChange={allDayChange} /> : <Switch label="All-day" checked={popupEventAllDay} onChange={allDayChange} />}
                {isEditable ? <Input disabled={true} ref={startRef} label="Starts" /> : <Input ref={startRef} label="Starts" />}
                {isEditable ? <Input disabled={true} ref={endRef} label="Ends" /> : <Input ref={endRef} label="Ends" />}
                <Datepicker
                    select="range"
                    controls={controls}
                    touchUi={true}
                    startInput={start}
                    endInput={end}
                    showRangeLabels={false}
                    responsive={responsiveOptions}
                    onChange={dateChange}
                    value={popupEventDate}
                />


                <div
                    className={`invoiced ${isEditable ? "completed" : ""}`}
                    style={{ backgroundColor: isEditable ? 'rgb(141, 244, 172)' : 'white' }}
                >
                    <Checkbox label={'Invoiced'} checked={isEditable} onChange={handleEventInvoiced} />
                </div>

                {isEdit && <div className="mbsc-button-group">
                    <Button className="mbsc-button-block" color="danger" variant="outline" onClick={onDeleteClick}>Delete event</Button>
                </div>}
            </div>
        </Popup>
        <Popup
            display="bottom"
            contentPadding={false}
            showArrow={false}
            showOverlay={false}
            anchor={colorAnchor}
            isOpen={colorPickerOpen}
            buttons={colorButtons}
            responsive={colorPopup}
            ref={colorPicker}
            onClose={onPickerClose}
        >
            <div className="crud-color-row">
                {colors.map((color, index) => {
                    if (index < 5) {
                        return <div key={index} onClick={changeColor} className={"crud-color-c " + (tempColor === color ? 'selected' : '')} data-value={color}>
                            <div className="crud-color mbsc-icon mbsc-font-icon mbsc-icon-material-check" style={{ background: color }}></div>
                        </div>
                    } else return null;
                })}
            </div>
            <div className="crud-color-row">
                {colors.map((color, index) => {
                    if (index >= 5) {
                        return <div key={index} onClick={changeColor} className={"crud-color-c " + (tempColor === color ? 'selected' : '')} data-value={color}>
                            <div className="crud-color mbsc-icon mbsc-font-icon mbsc-icon-material-check" style={{ background: color }}></div>
                        </div>
                    } else return null;
                })}
            </div>
        </Popup>

        {/* HOVER POPUP */}
        <Popup
            display="anchored"
            anchor={anchor}
            touchUi={false}
            showOverlay={false}
            contentPadding={false}
            closeOnOverlayClick={false}
            width={300}
            // cssClass="md-tooltip"
            isOpen={isHoverOpen} >
            <div className="md-tooltip-container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <div className="md-tooltip-header" style={{ backgroundColor: `${selectedColor}${Math.floor(0.4 * 255).toString(16).padStart(2, 0)}` }}>
                    <h1>{popupEventTitle}</h1>
                    <p>{popupEventDescription}</p>
                </div>
                <div className="md-tooltip-content-container">
                    <div className="md-tooltip-content">
                        <h2 className='selected-event-container'>Bemanning:</h2>
                        {eventContent.map(item => {
                            if (item.id <= 11) {
                                return <div className='selected-event-crew' key={item.id}>{item.name}</div>
                            }
                        })}
                        <h2 className='selected-event-container'>Utstyr:</h2>
                        {eventContent.map(item => {
                            if (item.id > 11) {
                                return <div className='selected-event-equipment' key={item.id}>{item.name}</div>
                            }
                        })}
                    </div>
                </div>
            </div>
        </Popup>
    </div>
}
export default Calendar;


// const [myEvents, setMyEvents] = React.useState();
// const [tempEvent, setTempEvent] = React.useState(null);
// const [isOpen, setOpen] = React.useState(false);
// const [isEdit, setEdit] = React.useState(false);
// const [anchor, setAnchor] = React.useState(null);
// const [start, startRef] = React.useState(null);
// const [end, endRef] = React.useState(null);
// const [popupEventTitle, setTitle] = React.useState('');
// const [popupEventDescription, setDescription] = React.useState('');
// const [popupEventAllDay, setAllDay] = React.useState(true);
// const [popupEventDate, setDate] = React.useState([]);
// const [popupEventStatus, setStatus] = React.useState('busy');
// const [mySelectedDate, setSelectedDate] = React.useState(new Date());
// const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
// const [colorAnchor, setColorAnchor] = React.useState(null);
// const [selectedColor, setSelectedColor] = React.useState('');
// const [tempColor, setTempColor] = React.useState('');
// const colorPicker = React.useRef();