import { useEffect, useState, useCallback, useRef } from 'react';
import { auth, signInWithRedirect, GoogleAuthProvider, sendPasswordResetEmail } from '../Utils/firebaseConfig'

import { Popup, toast} from '@mobiscroll/react';
import './LogIn.css';
import SignInForm from '../Components/SignInForm'



import logo from '../img/Banner_svg.png'
const LogIn = () => {

  const reset = useRef();
  const [isOpen, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [email, setEmail] = useState('')

  // const [isLoggedin, setIsLoggedIn] = useState(false)
  const [loginWithEmail, setloginWithEmail] = useState(false)
  const provider = new GoogleAuthProvider()


  const onPopupClose = useCallback(() => {
    setOpen(false);
  }, []);

  const openPopup = () => {
    setOpen(true)
  }

  const handleEmail = (event) => {
    setEmail(event.target.value);
  }

  const resetPassword = () => {
    console.log(email)
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast({
          message: `mail sent to ${email}` 
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
    setOpen(false);
  }

  useEffect(() => {
    document.addEventListener("mousedown", test);
  })

  auth.onAuthStateChanged((user) => {
    if (user) {
      // setIsLoggedIn(!!user);
      console.log('signed in!!!!!!')
      window.location.href = '/Agenda'

    } else {
      console.log('Sign in to access the calendar')
    }
  });

  const test = (e) => {
    if (e.target !== document.getElementById("login-container")) {
    } else {
      setloginWithEmail(false)
    }
  }



  return (
    <div className="main-wrapper">
      <div className="welcome-image-wrapper">
        <img className='welcome-image' alt='Logo' src={logo}></img>
        <span>Kalender</span>
        </div>
      <h1 className='buttons'>
        <button className='login-with-email-btn' onClick={() => setloginWithEmail(true)}> Log in with email</button>
      </h1>
      {loginWithEmail ? <SignInForm /> : ""}

      <div className="forgot-container">

        <button className='forgot' onClick={openPopup} >glemt passord</button>
      </div>

      <Popup
        display="bottom"
        contentPadding={false}
        showArrow={false}
        showOverlay={false}
        anchor={anchor}
        isOpen={isOpen}
        width={'100%'}
        // buttons={colorButtons}
        // responsive={colorPopup}
        ref={reset}
        onClose={onPopupClose}
      >
        <div className='confirm-dialog'>
          <div className='forgot-container'>
            <label htmlFor='forgot'>Fyll in din epost</label>
            <input id='forgot' value={email} onChange={handleEmail}></input>
            <h2 onClick={resetPassword}>Tilbakestill passord</h2>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default LogIn;