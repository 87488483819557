import React, { useState } from 'react';
import { toast } from '@mobiscroll/react';

import './SignInForm.css'

import { auth, signInWithEmailAndPassword } from '../Utils/firebaseConfig';

const SignInForm = (onExitPopup) => {


  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')


const logIn = () => {signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      // const user = userCredential.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      let message = error.message.replace('Firebase:','');
      toast({
        message: `${message}`,
        color: 'danger'
      });
      console.log(errorMessage, errorCode)
    });
  }



  const handlePassword = (event) => {
    setPassword(event.target.value);

  }
  const handleEmail = (event) => {
    setEmail(event.target.value);
  }


  return (
    <div id="login-container" className='login-container'>
      <div id='login-form' className='login-form'>
        <form action="">
          <p>Email</p><input placeholder='Enter Email' type="email" value={email} onChange={handleEmail} />
          <p>Password</p><input placeholder='Enter Password' type="password" value={password} onChange={handlePassword} />
        </form>
        <button onClick={logIn}>Log-in</button>
      </div>
    </div>
  )
}

export default SignInForm;


	// <div class="contact-form">
	// 	<img alt="" class="avatar" src="https://i.postimg.cc/zDyt7KCv/a1.jpg">
	// 	<h2>Contact Form</h2>
	// 	<form action="">
	// 		<p>Email</p><input placeholder="Enter Email" type="email">
	// 		<p>Passwrod</p><input placeholder="Enter Password" type="password"> <input type="submit" value="Sign in">
	// 		<p><input type="checkbox">Remember Me</p>
	// 	</form>
	// </div>
