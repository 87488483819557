const resources = [
  {
    id: 100,
    name: 'Bemanning',
    color: '#ff9900',
    styling: 'header',
    children: [
      {
        id: 1,
        name: 'Frode Kjøllesdal',
        color: '#ffeb3c',
        styling: 'person',
        email: 'frode@nordfjordvegservice.no'
      }, {
        id: 2,
        name: 'Matias Lien',
        color: '#f44437',
        styling: 'person',
        email: 'matias.lien@nordfjordvegservice.no',
      }, {
        id: 3,
        name: 'Kristian Aardal',
        color: '#3f51b5',
        styling: 'person',
        email: 'kristian@nordfjordvegservice.no'
      }, {
        id: 4,
        name: 'Idar Hatlegjerde',
        color: '#4baf4f',
        styling: 'person',
        email: 'test@test.com'
      }, {
        id: 5,
        name: 'Ronny',
        color: '#ff9900',
        styling: 'person',
        email: 'test2@test.com'
      }, {
        id: 6,
        name: 'Li2',
        color: '#f7961e',
        background: '#76e083',
      }, {
        id: 7,
        name: 'Øystein',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 8,
        name: 'Anders L',
        color: '#f7961e',
        background: '#76e083',
      }, {
        id: 9,
        name: 'Olav',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 10,
        name: 'Anfinn',
        color: '#f7961e',
        background: '#76e083',
      }, {
        id: 11,
        name: 'Håvard',
        color: '#34c8e0',
        background: '#76e083',
      }
    ],
  }, {
    id: 101,
    name: 'Maskinpark',
    color: '#ff9900',
    styling: 'header',
    children: [
      {
        id: 20,
        name: 'Cat 305,5',
        color: '#f7961e',
        background: '#76e083',
      }, {
        id: 21,
        name: 'Yc 85-8',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 22,
        name: 'Cat 305,5',
        color: '#f7961e',
        background: '#76e083',
      }, {
        id: 23,
        name: 'Cat 307,5',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 24,
        name: 'Cat 313F',
        color: '#f7961e',
        background: '#76e083',
      }, {
        id: 25,
        name: 'JD 6155R JW1925',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 26,
        name: 'JD 6155R JW1925',
        color: '#f7961e',
        background: '#76e083',
      }, {
        id: 27,
        name: 'JD 6130R BK2826',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 28,
        name: 'JD 6215R LC9418',
        color: '#f7961e',
        background: '#76e083',
      }, {
        id: 29,
        name: 'Caddy',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 30,
        name: 'Transporter',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 31,
        name: 'Picup',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 32,
        name: 'Sticset 500',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 33,
        name: 'Ducker dua 700',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 34,
        name: 'Junkkari flishugger',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 35,
        name: 'Foss eik',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 36,
        name: 'Metsjø',
        color: '#34c8e0',
        background: '#76e083',
      }, {
        id: 37,
        name: ' Scania 580R',
        color: '#34c8e0',
        background: '#76e083',
      }
    ],
  },
];

export { resources }