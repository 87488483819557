import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator, connectDatabaseEmulator, signInWithRedirect, signInWithEmailAndPassword, GoogleAuthProvider, getRedirectResult, onAuthStateChanged, signOut, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator, collection, getDocs, doc, addDoc, query, where, updateDoc, deleteDoc, onSnapshot } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID ,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase)
const database = getFirestore(firebase);

if (window.location.hostname.includes("localhost")) {
  // Point to the RTDB emulator running on localhost.
  connectFirestoreEmulator(database, 'localhost', 8080);
  connectAuthEmulator(auth, "http://localhost:9100");
} 



export { 
  firebase, auth, database, 
  signInWithRedirect, GoogleAuthProvider, getRedirectResult, onAuthStateChanged, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail,
  collection, getDocs, doc, addDoc, query, where, updateDoc, deleteDoc, onSnapshot
}